/* You can add global styles to this file, and also import other style files */
.logo-font {
    font-family: titillium web, sans-serif
}

html {
    scroll-behavior: smooth;
    position: relative;
    font-family: 'Helvetica Neue';
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0;
    background: #E5E5E5;
}

a {
    color: #1f1e1e;
    text-decoration: none
}

img {
    vertical-align: middle
}
.spinner-message {
    text-align: center;
}
caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #818a91;
    text-align: left;
    caption-side: bottom
}

small, .small {
    font-size: 80%;
    font-weight: 400
}

mark, .mark {
    padding: .2em;
    background-color: #fcf8e3
}

footer {
    background: #f3f3f3;
    bottom: 0;
    width: 100%
}

footer .logo-font {
    vertical-align: middle
}

footer .attribution {
    vertical-align: middle;
    margin-left: 10px;
    font-size: .8rem;
    color: #bbb;
    font-weight: 300
}

.deal-meta {
    width: 48%;
    display: block;
    position: relative;
    font-weight: 300;
    margin-top: 0.7rem;
}

.deal-meta img {

}

.deal-meta .info {
    display: inline-block;
    vertical-align: middle;
    line-height: 1rem
}

.deal-meta .info .author {
    display: block;
    font-weight: 500 !important
}

.deal-meta .info .date {
    color: #bbb;
    font-size: .8rem;
    display: block
}

.deal-preview {
    position: relative;
    height: 100%;
    width: 100%;
    max-lines: 2;

}
.deal-preview-link {
    line-height: 1.2em;
    color: inherit!important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 2.4em;
}

.deal-preview-link:hover {
    text-decoration: inherit !important
}
.deal-preview .deal-preview-link h1 {
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    margin-bottom: 3px
}

.deal-preview .deal-preview-link p {
    font-weight: 300;
    color: #999;
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 1.3rem
}

.deal-preview .deal-preview-link span {
    max-width: 30%;
    font-size: .8rem;
    font-weight: 300;
    color: #bbb;
    vertical-align: middle
}

.deal-preview .deal-preview-link ul {
    float: right;
    max-width: 50%;
    vertical-align: top
}

.deal-preview .deal-preview-link ul li {
    font-weight: 300;
    font-size: .8rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.btn .counter {
    font-size: .8rem !important
}

.home-page .banner {
    background: #E84E1B;
    box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, .3), inset 0 -8px 8px -8px rgba(0, 0, 0, .3)
}

.home-page .banner p {
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 300 !important;
    margin-bottom: 0
}

.home-page .banner h1 {
    text-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    font-weight: 700 !important;
    text-align: center;
    font-size: 3.5rem;
    padding-bottom: .5rem
}

.home-page .feed-toggle {
    margin-bottom: -1px
}

.home-page .highlights {
    padding: 5px 10px 10px;
    background: #f3f3f3;
    border-radius: 4px
}

.home-page .highlights p {
    margin: .2rem
}

.deal-page .banner {
    padding: 2rem 0
}

.deal-page .banner h1 {
    font-size: 2.8rem;
    font-weight: 600
}

.deal-page .banner .btn {
    opacity: .8
}

.deal-page .banner .btn:hover {
    transition: .1s all;
    opacity: 1
}

.deal-page .banner .deal-meta {
    margin: 2rem 0 0
}

.deal-page .banner .deal-meta .author {
    color: #fff
}

.deal-page .deal-content p {
    font-family: 'source serif pro', serif;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 2rem
}

.deal-page .deal-content h1, .deal-page .deal-content h2, .deal-page .deal-content h3, .deal-page .deal-content h4, .deal-page .deal-content h5, .deal-page .deal-content h6 {
    font-weight: 500 !important;
    margin: 1.6rem 0 1rem
}

.deal-page .deal-actions {
    text-align: center;
    margin: 1.5rem 0 3rem
}

.deal-page .deal-actions .deal-meta .info {
    text-align: left
}

.deal-page .comment-form .card-block {
    padding: 0
}

.deal-page .comment-form .card-block textarea {
    border: 0;
    padding: 1.25rem
}

.deal-page .comment-form .card-footer .btn {
    font-weight: 700;
    float: right
}

.deal-page .comment-form .card-footer .comment-author-img {
    height: 30px;
    width: 30px
}

.deal-page .card {
    border: 1px solid #e5e5e5;
    box-shadow: none !important
}

.deal-page .card .card-footer {
    border-top: 1px solid #e5e5e5;
    box-shadow: none !important;
    font-size: .8rem;
    font-weight: 300
}

.deal-page .card .comment-author-img {
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    width: 20px;
    border-radius: 30px
}

.deal-page .card .comment-author {
    display: inline-block;
    vertical-align: middle
}

.deal-page .card .date-posted {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    color: #bbb
}

.deal-page .card .mod-options {
    float: right;
    color: #333;
    font-size: 1rem
}

.deal-page .card .mod-options i {
    margin-left: 5px;
    opacity: .6;
    cursor: pointer
}

.deal-page .card .mod-options i:hover {
    opacity: 1
}

.profile-page .user-info {
    text-align: center;
    background: #f3f3f3;
    padding: 2rem 0 1rem
}

.profile-page .user-info .user-img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-bottom: 1rem
}

.profile-page .user-info h4 {
    font-weight: 700
}

.profile-page .user-info p {
    margin: 0 auto .5rem;
    color: #aaa;
    max-width: 450px;
    font-weight: 300
}

.profile-page .user-info .action-btn {
    float: right;
    color: #999;
    border: 1px solid #999
}

.profile-page .deals-toggle {
    margin: 1.5rem 0 -1px
}

.editor-page .tag-list i {
    font-size: .6rem;
    margin-right: 5px;
    cursor: pointer
}
.deal-preview-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 1;
}
.header-logo {
    height: 86px;
    min-width: 100px;
    max-width: 40%;
    background-image: url("assets/header_logo.png");
}
.footer-logo {
    height: 86px;
    min-width: 100px;
    background-image: url("assets/header_logo.png");
}
.header-login-button{
    display: flex;
    height: 40px;
    background: #FD5E04;
    box-shadow: 2px 2px 3px #00000040;
    border-radius: 6px;
    border: none;
    align-items: center;
    justify-content: center;
}
.header-login-button a{
    font-family: 'Helvetica Neue';
    font-weight: 450;
    font-size: 1.5rem;
    line-height: 100%;
    color: white;
    text-decoration: none;
    text-align: center;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
}
.login-button a:hover{
    text-decoration: none;
    color: #FFFFFF;
}
.deal-preview-go-to-deal {
    display: flex;
    width: 100%;
    height: 40px;
    background: #FD5E04;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: none;
}
.deal-preview-go-to-deal img {
    max-height: 65%;
    align-self: center;
    width: 100%;
}
.deal-details-go-to-deal {
    display: flex;
    width: 135px;
    height: 45px;
    background: #FD5E04;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: none;
    justify-content: space-around;
    align-items: center;
    font-weight: 600;
    color: white;
}
.deal-details-go-to-deal:hover{
    font-weight: 700;
    color: white;
}
.deal-details-go-to-deal img {
    max-height: 65%;
    align-self: center;
}
small {
    margin-top: 0.25rem;
    display: block;
    text-align: center;
}
.deal-preview-footer {
    display: flex;
    position: absolute;
    bottom: 5px;
    width: 100%;
}
.deal-meta-button {
    border: 1px solid #C5C5C5;
    box-sizing: border-box;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    background-color: white;
    align-items: center;
}
.deal-flat-button {
    height: 40px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}
.deal-flat-button:hover {
    color: black;
    text-decoration: none;
}
.deal-meta-col-1{
    max-width: 25%;
    min-width: 20%;
    margin: auto;
}
.deal-meta-col-2{
    max-width: 50%;
    min-width: 33%;
    margin: auto;
}
.deal-meta-col-3{
    max-width: 25%;
    min-width: 20%;
    margin: auto;
}
.deal-preview-content{
    padding: 5px;
}
.deal-meta-shape {
    display: flex;
    align-items: center;
    background: #1F1E1E;
    border-radius: 4px;
    max-height: 38px;
    min-height: 26px;
    color: white;
    margin-bottom: 5px;
    font-size: 0.85rem;
}
.deal-meta-store-shape {
    display: flex;
    align-items: center;
    border-radius: 4px;
    max-height: 38px;
    min-height: 26px;
    font-weight: 300;
    color: #1F1E1E;
    margin-top: 10px;
}
.deal-meta-time {
    padding: 2px;
    max-height: 24px;
    width: 32px;
}
.deal-meta-shape a {
    color: white;
}
.deal-meta-shape a:hover {
    text-decoration: none;
}
.deal-meta-store-shape a {
    color: black;
}
.deal-meta-store-shape a:hover {
    color: black;
    text-decoration: none;
    font-weight: bolder;
}
.deal-preview-price {
    font-family: 'Helvetica Neue';
    color: #E84E1B;
}
.strikethrough {
    text-decoration: line-through;
    color: #1F1E1E!important;
}
.deal-preview-saving {
    font-family: 'Helvetica Neue';
    color: #999999;
}
app-deal-meta {
    display: flex;
    width: 100%;
}
.header-style {
    background: linear-gradient(180deg, #1F1E1E 0%, #333333 64.58%, rgba(51, 51, 51, 0.97) 83.33%, rgba(51, 51, 51, 0.92) 100%);
}
.header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.action-btn-style {
    background: linear-gradient(225.22deg, #1F1E1E 14.86%, rgba(31, 30, 30, 0) 80.45%), rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    aspect-ratio: 1;
}
.action-btn-style img {
    width: 80%;
    height: 80%;
}
.action-social-btn {
    max-height: 64px;
    max-width: 64px;
}
.action-add-btn {
    max-height: 100px;
    max-width: 100px;
}

.deal-details-meta-container {
    display: flex;
}
.deal-details-meta-container img {
    max-height: 100%;
    max-width: 100%;
}
.deal-details-meta-col-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.deal-details-meta-col-4 h1 {
    width: 100%;
}
.deal-container {
    background: #FFFFFF;
}
a:hover {
    cursor: pointer;
}
.header-search-categories {
    background: #FFFFFF;
    padding: 0.5rem 1rem 0.5rem 1rem;
}
.submit-deal-container {
    margin: auto;
    padding: 5%;
}
.text-field-icon {

}
.p-float-label>p-inputNumber input{
    border-radius: 0 3px 3px 0;
}
.p-component-overlay {
    z-index: 999;
}
.p-float-label>p-inputNumber {
    border: none;
    padding: 0;
}
.p-inputgroup>p-calendar input{
    border-radius: 0 3px 3px 0;
}

.p-inputgroup>p-calendar {
    border: none;
    padding: 0;
}
.p-dropdown-panel, .p-datepicker {
    z-index: 5;
}
.card {
    background: white;
    padding: 3rem;
}
.p-galleria-thumbnail-img-content>img {
    max-height: 50px;
    max-width: 50px;
}
.formgrid {
    margin-top: 1rem!important;
}
ngx-dropzone-image-preview {
    padding: 5%!important;
    height: 100%!important;
}
ngx-dropzone-image-preview img{
    max-width: 100%;
}
ngx-dropzone {
    aspect-ratio: 1;
    align-self: center;
    height: 85%!important;
}
.submit-image-field {
    text-align: center;
    text-align: -webkit-center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.app-auth-page-grid {
    flex-direction: column;
}
.field>small {
    text-align: start;
}
.p-toast {
    z-index: 1200;
}
p-paginator {
    margin: auto;
}
.p-paginator {
    background: none;
}
.deal-preview-card {
    background: #FFFFFF;
    box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.p-treeselect {
    width: 100%;
}
.icon-telegram {
    content: url("assets/telegram.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-twitter {
    content: url("assets/twitter.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-twitter:hover {
    color: #E84E1B;
}
.icon-facebook {
    content: url("assets/facebook.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-plus {
    content: url("assets/plus.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-contact {
    content: url("assets/contacts.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-store {
    content: url("assets/store.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-calendar {
    content: url("assets/calendar.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-hearth {
    content: url("assets/hearth.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-message {
    content: url("assets/message.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-circle-dollar {
    content: url("assets/circle_dollar.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-shield {
    content: url("assets/shield.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-trophy {
    content: url("assets/trophy.svg");
    width: 100%;
    height: 100%;
    contain: strict;
}
.icon-filter {
    content: url("assets/filter.svg");
}
.icon-cart {
    content: url("assets/cart.svg");
}
.icon-liked {
    content: url("assets/liked.svg");
}
.icon-disliked {
    content: url("assets/disliked.svg");
}
.icon-like {
    content: url("assets/like.svg");
}
.icon-dislike {
    content: url("assets/dislike.svg");
}
.p-button.p-button-header-social,
.p-button-header-social {
    background: #3b3b3b!important;
    border: 1px solid #2c2c2c!important;
}
.p-button.p-button-header-social:hover,
.p-button.p-button-header-social:active,
.p-button.p-button-header-social:focus {
    background: #232323!important;
    border-color: #202020!important;
}
.p-button-dlnd {
    background: #FD5E04!important;
    border-color: #FD5E04!important;;
    color: #ffffff!important;;
}
.p-button-dlnd:hover {
    background: #e05c10 !important;
    border-color: #e05c10!important;
    color: #ffffff!important;
}
.p-button-dlnd:active {
    background: #e05c10 !important;
    border-color: #e05c10!important;
    color: #ffffff!important;
}
.p-button-dlnd:focus {
    background: #e05c10 !important;
    border-color: #e05c10!important;
    color: #ffffff!important;
    box-shadow: 0 0 0 0.2rem #ec5a08;
}
.p-button-dlnd-dark {
    background: #333333!important;
    border-color: #282828 !important;;
    color: #ffffff!important;;
}
.p-button-dlnd-dark:hover {
    background: #333333 !important;
    border-color: #333333!important;
    color: #ffffff!important;
}
.p-button-dlnd-dark:active {
    background: #282828 !important;
    border-color: #282828!important;
    color: #ffffff!important;
}
.p-button-dlnd-dark:focus {
    background: #282828 !important;
    border-color: #282828!important;
    color: #ffffff!important;
    box-shadow: 0 0 0 0.2rem #464646;
}
.p-button-orange {
    background: #FD5E04!important;
    border-color: #FD5E04!important;
    color: #ffffff;
}
.p-button-orange:hover {
    background: #e05c10 !important;
    border-color: #e05c10!important;
    color: #ffffff!important;
}
.comment-content>p {
    margin-top: 1px;
}
.related-deal {
    cursor: pointer;
}
.p-password {
    width: 100%;
}
.setting-profile-follow {
    background: #333333;
}
.setting-profile-user {
    background: #FD5E04;
}
/*@media only screen and (max-width: 767px) {*/
/*    setting-profile-left-panel {*/
/*        min-height: 500px;*/
/*    }*/
/*}*/
.setting-profile-left-panel {
    background: #ababab;
}
.profile-avatar-picture {
    margin-top: -7.5rem;
    aspect-ratio: 1;
}

@media only screen and (max-width: 1200px){
    .info {
        width: 50%;
    }
    .deal-container {

    }
}
@media only screen and (min-width: 1600px){
    .header-container,
    .explore-container,
    .breadcrumb-container,
    .deal-container {
        padding-left: 16rem;
        padding-right: 16rem;
    }
}
@media only screen and (min-width: 1450px) and (max-width: 1599px){
    .header-container,
    .explore-container,
    .breadcrumb-container,
    .deal-container {
        padding-left: 11rem;
        padding-right: 11rem;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1449px){
    .header-container,
    .explore-container,
    .breadcrumb-container,
    .deal-container {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}
.deal-preview-image {
    max-height: 220px;
    max-width: 300px;
}
.deal-related-image {
    height: 100px;
    width: 100px;
    object-fit: contain;
}
.deal-detail-image {
    object-fit: contain;
    max-height: 400px;
    max-width: 450px;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    width: -moz-available;
    height: -moz-available;
}
@media only screen and (min-width: 600px) and (max-width: 1449px){
    .header-container,
    .explore-container,
    .breadcrumb-container,
    .deal-container {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}
@media only screen and (max-width: 599px){
    .header-container,
    .explore-container
    {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .breadcrumb-container,
    .deal-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
@media only screen and (min-width: 1201px){
    .about {
        max-width: 24rem;
    }
}
@media only screen and (max-width: 1200px){
    .about {
        max-width: 24rem;
    }
}
@media only screen and (max-width: 700px){
    .deal-detail-image {
        max-height: 250px!important;
        max-width: 300px!important;
    }
    .info {
        width: 100%;
    }
    .formgrid{
        flex-direction: column;
    }
    h6 {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }
    .field {
        margin-top: 1rem;
    }
}
@media only screen and (max-width: 767px) {
    .username-margin {
        margin-left: 14rem!important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
    .username-margin {
        margin-left: 17rem!important;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .username-margin {
        margin-left: 18rem!important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1450px) {
    .username-margin {
        margin-left: 19rem!important;
    }
}
.username-margin {
    margin-left: 20rem;
}
.p-speeddial-action {
    background: #FD5E04;
}
.p-button {
    border-radius: 8px;
}
.p-button.p-button-black,
.p-buttonset.p-button-black>.p-button,
.p-splitbutton.p-button-black>.p-button {
    color: #fff;
    background: #383838;
    border: 1px solid #262525;
}
.p-button.p-button-black:active,
.p-buttonset.p-button-black>.p-button:active,
.p-splitbutton.p-button-black>.p-button:active {
    color: #fff;
    background: #333333;
    border: 1px solid #262525;
}
.p-button.p-button-black:hover,
.p-buttonset.p-button-black>.p-button:hover,
.p-splitbutton.p-button-black>.p-button:hover {
    color: #fff;
    background: #333333;
    border: 1px solid #262525;
}

.p-button.p-button-black:focus,
.p-buttonset.p-button-black>.p-button:focus,
.p-splitbutton.p-button-black>.p-button:focus {
    color: #fff;
    background: #333333;
    border: 1px solid #262525;
    box-shadow: 0 0 0 0.2rem #646464;
}
/*To user for disabling the setting button*/
/*.p-button.p-button-black,*/
/*.p-buttonset.p-button-black>.p-button,*/
/*.p-splitbutton.p-button-black>.p-button {*/
/*    color: #ffffff99;*/
/*    background: #38383899;*/
/*    border: 1px solid #26252599;*/
/*}*/
/*.p-button.p-button-black:active,*/
/*.p-buttonset.p-button-black>.p-button:active,*/
/*.p-splitbutton.p-button-black>.p-button:active {*/
/*    color: #ffffff99;*/
/*    background: #33333399;*/
/*    border: 1px solid #26252599;*/
/*}*/
/*.p-button.p-button-black:hover,*/
/*.p-buttonset.p-button-black>.p-button:hover,*/
/*.p-splitbutton.p-button-black>.p-button:hover {*/
/*    color: #ffffff99;*/
/*    background: #33333399;*/
/*    border: 1px solid #26252599;*/
/*}*/

/*.p-button.p-button-black:focus,*/
/*.p-buttonset.p-button-black>.p-button:focus,*/
/*.p-splitbutton.p-button-black>.p-button:focus {*/
/*    color: #ffffff99;*/
/*    background: #33333399;*/
/*    border: 1px solid #26252599;*/
/*    box-shadow: 0 0 0 0.2rem #64646499;*/
/*}*/
.p-splitbutton {
    border-radius: 8px;
}
.deal-expired {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    opacity: 0.6;
}
.vl {
    margin-top: 3rem;
    margin-bottom: 3rem;
    background-color: #5a5a5a;
    width: 1px;
}
.cpr {
    color: #272d2f;
}
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #333333!important;
    }
    40% {
        stroke: #FD5E04!important;;
    }
    66% {
        stroke: #333333!important;;
    }
    80%,
    90% {
        stroke: #FD5E04!important;;
    }
}
.policy-container a {
    color: #006fff;
}
.p-carousel-item {
    padding-left: .25rem;
    padding-right: .25rem;
}
.expired-label {
    position: absolute;
    z-index: 10;
}
.login-popup {
    z-index: 10000;
    width: 100%;
    max-width: 500px;
}
.p-badge {
    background: #FD5E04;
    color: #FFFFFF;
}
.p-slider .p-slider-range {
    background: #FD5E04;
}
.p-slider .p-slider-handle:focus {
    box-shadow: 0 0 0 0.2rem #f3742b;
}
.p-slider .p-slider-handle {
    border: 2px solid #FD5E04;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #FD5E04;
    border-color: #FD5E04;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #FD5E04;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #FD5E04;
    background: #FD5E04;
}
.p-multiselect:not(.p-disabled):hover {
    border-color: #FD5E04;
}
.p-inputtext:enabled:hover {
    border-color: #FD5E04;
}
.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #f88038;
    border-color: #FD5E04;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background: #FD5E04;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #f88038;
}
.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #f88038;
}
.avatar-overlay {
    background: transparent;
    position: absolute;
    opacity: 0;
    transition: opacity 700ms;
}
.avatar-overlay:hover {
    background: rgba(77, 40, 26, 0.6);
    opacity: 1;
    transition: opacity 300ms;
}
.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #f88038;
}
